<template>
  <div class="discography">

    <br>
  <div class ="player d-flex justify-content-center">
    <!-- Spotify  -->
          <iframe class="spotify-player d-flex justify-content-center"
            src="https://open.spotify.com/embed/playlist/22ukPTTLLYal8Um9oh4mPX"
            width="800"
            height="300"
            frameborder="0"
            allowtransparency="true"
            allow="encrypted-media"
          ></iframe>
  </div>
  <br>

<div class="music "> 
     <b-row v-if="music != null">
         <b-card  bg-variant="light" border-variant="secondary" v-for="m in music.items" :key="m.id"
         :title="m.name"
         :img-src="m.images[0].url"
            :img-alt="m.album_type"
            img-top
            tag="article"
            class="m-4 text-center"
         >
         <b-card-text>
              {{m.album_type}}
          </b-card-text>
          <b-button :href="m.external_urls.spotify" variant="dark" target="_blank">Spotify</b-button>
          <br>
          <br>
          <b-button href="https://music.apple.com/us/artist/rahsaan-hakeem/1529633574"  target="_blank" variant="dark">Apple Music</b-button>
         </b-card>
       </b-row>
  </div>


  <!-- <div class="projects d-flex justify-content-center  mb-5"> 
     <b-card-group deck>
         <b-card  v-for="p in projects.Albums" :key="p.rdate"
         :title="p.name"
         :img-src="p.img"
            :img-alt="p.type"
            img-top
            tag="article"
            style="max-width: 20rem;"
            class="mb-2 text-center"
         >
         <b-card-text>
              {{p.type}}
          </b-card-text>
          <b-button href="#" variant="dark">Listen</b-button>
         </b-card>
       </b-card-group>
  </div>

  <div class="singles d-flex justify-content-center"> 
       <b-card-group deck>
         <b-card  v-for="p in projects.SinglesandEPs" :key="p.rdate"
         :title="p.name"
         :img-src="p.img"
            :img-alt="p.type"
            img-top
            tag="article"
            style="max-width: 20rem;"
            class="mb-2 text-center"
         >
         <b-card-text>
              {{p.type}}
          </b-card-text>
          <b-button href="#" variant="dark">Listen</b-button>
         </b-card>
       </b-card-group>
  </div> -->
<br>
  </div>
</template>

<script>
import Spotify from "node-spotify-api"


const spotify = new Spotify({
  id: "d37e32659d4f4a02b1f65a8b841214c1",
  secret: "98da41f097534d99872f1140fd08b1ec"
});

export default {
  name: "disc",
  data(){
    return{
      music : null
    }
  },
   mounted () {
     spotify.request(`https://api.spotify.com/v1/artists/6nyRfYMXUpuOAgl2x2GSwU/albums`)
    .then((data) => {
      // console.log(data);
      data.items.sort((a,b) =>{
        return  new Date(b.release_date).getTime() -  new Date(a.release_date).getTime();
      }); 
      this.music = data;
    })
   }
};
</script>

<style scoped>
.spotify-player{
  width: 80%;
  margin: 10px auto;
}


 /* Small devices (landscape phones, 576px and up) */
  @media (min-width: 576px) {

  }

  /* Medium devices (tablets, 768px and up) */
  @media (min-width: 768px) {

  }

  /* Large devices (desktops, 992px and up) */
  @media (min-width: 992px) {

  }

  /* Extra large devices (large desktops, 1200px and up) */
  @media (min-width: 1200px) {

  }
</style>
